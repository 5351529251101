import { notification } from 'antd'

const axiosErrorUtil = (e) => {
  console.log(e)
  let errMessage = 'Something goes wrong. Please, try again or contact us'
  if (e.response) {
    errMessage = e.response.data.message
  }
  notification.error({
    message: 'Error',
    description: errMessage,
  })
  return true
}

export default axiosErrorUtil
