import React from 'react'
import styles from './user-menu.module.scss'
import { RiLogoutBoxLine, RiSettings4Line, RiUser2Line } from 'react-icons/ri'
import { Drawer } from 'antd'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import actions from '../../../redux/users/actions'

const UserMenu = ({ user }) => {
  const dispatch = useDispatch()
  const [userMenu, setUserMenu] = React.useState(false)

  return (
    <>
      <button
        className={styles.userMenu}
        onClick={() => setUserMenu(!userMenu)}
      >
        <div className={styles.icon}>
          <RiUser2Line />
        </div>
        <div className={styles.username}>
          {user.first_name} {user.last_name}
        </div>
      </button>
      <Drawer
        open={userMenu}
        width={420}
        title={`${user.first_name} ${user.last_name}`}
        onClose={() => setUserMenu(false)}
      >
        <div className={styles.userMenuDrawer}>
          <ul>
            <li>
              <Link to="/app/settings" onClick={() => setUserMenu(false)}>
                <RiSettings4Line /> <span>Settings</span>
              </Link>
            </li>
            <li>
              <Link
                to="/app/logout"
                onClick={(e) => {
                  e.preventDefault()
                  setUserMenu(false)
                  dispatch({
                    type: actions.LOGOUT,
                    payload: {},
                  })
                }}
              >
                <RiLogoutBoxLine /> <span>Logout</span>
              </Link>
            </li>
          </ul>
        </div>
      </Drawer>
    </>
  )
}

export default UserMenu
