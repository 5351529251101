import React from 'react'
import { DateTime } from 'luxon'
import pkg from '../../../../package.json'
import styles from './footer.module.scss'

const AppFooter = () => (
  <div className={styles.footer}>
    <div className={styles.copy}>
      <p className="mb-0">
        © PositiveTrades, {DateTime.now().toFormat('yyyy')}
      </p>
    </div>
    <div className={styles.copy}>
      <p className="mb-0">v.{pkg.version}</p>
    </div>
  </div>
)

export default AppFooter
