import React from 'react'
import AdminMenu from '../../../data/menus/admin-menu'
import UserMenu from '../../../data/menus/user-menu'
import { Link, useLocation } from 'react-router-dom'
import { HiDotsHorizontal, HiOutlineX } from 'react-icons/hi'
import styles from './mobile-bottom-menu.module.scss'

const MobileBottomMenu = ({ sidebarOpen, user, setSidebarOpen }) => {
  const location = useLocation()

  const getMenu = () => {
    if (user.is_admin) {
      return AdminMenu
    }

    return UserMenu
  }

  return (
    <div className={styles.mbMenu}>
      <div className={styles.wrapper}>
        <ul>
          {getMenu().map((item) => {
            if (!item.mobile) {
              return null
            }

            return (
              <li
                key={`sidebarMenu_${item.key}`}
                data-active={
                  location.pathname === '/app'
                    ? item.path === '/app'
                    : location.pathname.includes(item.path) &&
                      item.path !== '/app'
                }
              >
                <Link to={item.path}>{item.icon}</Link>
              </li>
            )
          })}
          <li>
            <Link
              to="/app"
              onClick={(e) => {
                e.preventDefault()
                setSidebarOpen(!sidebarOpen)
              }}
            >
              {sidebarOpen ? <HiOutlineX /> : <HiDotsHorizontal />}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default MobileBottomMenu
