import React from 'react'

import AppSidebar from './app/Sidebar'

import styles from './main.module.scss'
import { useSelector } from 'react-redux'
import AppHeader from './app/Header'
import AppFooter from './app/Footer'
import MobileBottomMenu from './app/MobileBottomMenu'

const MainLayout = ({ children }) => {
  const { user } = useSelector((state) => state.users)
  const [sidebarOpen, setSidebarOpen] = React.useState(false)

  return (
    <div className={styles.layout} data-layout="app">
      <AppHeader user={user} />
      <div
        className={`animate__animated animate__fadeIn ${styles.sidebar}`}
        data-open={sidebarOpen}
      >
        <AppSidebar user={user} setSidebarOpen={setSidebarOpen} />
      </div>
      <div className={` ${styles.wrapper}`}>
        <div className="container container__main">
          <main>
            <div className="animate__animated animate__fadeIn animate__slow">
              {children}
            </div>
          </main>
          <AppFooter />
        </div>
      </div>
      <MobileBottomMenu
        user={user}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
      />
    </div>
  )
}

export default MainLayout
