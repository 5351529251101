import React, { useEffect } from 'react'
import styles from './header.module.scss'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { RiArrowLeftLine } from 'react-icons/ri'
import UserMenu from './UserMenu'

const AppHeader = ({ user }) => {
  const metadata = useSelector((state) => state.metadata)
  const [headerOffset, setHeaderOffset] = React.useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY > 5) {
        setHeaderOffset(true)
      } else {
        setHeaderOffset(false)
      }
    })

    return () => {
      window.removeEventListener('scroll', () => {
        setHeaderOffset(false)
      })
    }
  }, [])

  return (
    <div
      className={`animate__animated animate__fadeIn ${styles.header}`}
      data-headerOffset={headerOffset}
    >
      <div className="container container__main">
        <div className={styles.wrapper}>
          <div className={styles.left}>
            <div className={styles.title}>
              {metadata.backPath && (
                <div className={styles.backPath}>
                  <Link to={metadata.backPath}>
                    <RiArrowLeftLine />
                  </Link>
                </div>
              )}
              <div className={styles.pageTitle}>
                {metadata.title || 'PositiveTrades'}
              </div>
            </div>
            {metadata.breadcrumbs && (
              <div className={styles.breadcrumbs}>
                <ul>
                  {metadata.breadcrumbs.length > 0 &&
                    metadata.breadcrumbs.map((item) => (
                      <li key={`breadcrumbs_${item.path}_${item.name}`}>
                        {item.path ? (
                          <Link to={item.path}>{item.name}</Link>
                        ) : (
                          <span>{item.name}</span>
                        )}
                      </li>
                    ))}
                </ul>
              </div>
            )}
          </div>
          <div>
            <UserMenu user={user} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppHeader
