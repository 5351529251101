import React from 'react'

const AuthLayout = ({ children }) => {
  return (
    <div className="app-body">
      <div>{children}</div>
    </div>
  )
}

export default AuthLayout
