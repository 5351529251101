import { all, takeEvery, put, call } from 'redux-saga/effects'
import { push } from 'redux-first-history'
import actions from './actions'
import localStorageHelper from '../../utils/local-storage.util'
import { message } from 'antd'
import axiosRequest from '../../utils/axios/axios-request.util'
import axiosErrorUtil from '../../utils/axios/axios-error.util'
import { history } from '../../index'

export function* LOGIN({ payload }) {
  try {
    yield put({ type: actions.SET_STATE, payload: { loading: true } })
    yield call(axiosRequest, 'sanctum/csrf-cookie', 'get', null, false)
    const login = yield call(axiosRequest, 'api/v1/auth/login', 'post', payload)
    if (login.data.success && login.data.token) {
      localStorageHelper.set('apiToken', login.data.token)
      message.success({ content: 'Welcome back!' })
      yield put({
        type: actions.SET_STATE,
        payload: {
          user: login.data.user,
          isLoggedIn: true,
          loading: false,
        },
      })
      yield put(push('/app'))
    } else {
      yield put({
        type: actions.SET_STATE,
        payload: { loading: false, isLoggedIn: false },
      })
    }
  } catch (e) {
    axiosErrorUtil(e)
    yield put({
      type: actions.SET_STATE,
      payload: { loading: false, isLoggedIn: false },
    })
  }
}

export function* LOGOUT() {
  localStorageHelper.remove('apiToken')
  yield put({
    type: 'user/SET_STATE',
    payload: {
      loading: false,
      isLoggedIn: false,
      initialStateLoading: false,
    },
  })
  window.location.reload()
}

export function* AUTH_CHECK() {
  yield put({
    type: actions.SET_STATE,
    payload: { loading: true, initialStateLoading: true },
  })
  let authorized = false
  const token = localStorageHelper.get('apiToken')
  try {
    if (!token) {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: false,
          isLoggedIn: false,
          initialStateLoading: false,
        },
      })
      if (window && window.location.pathname !== '/') {
        yield put(push('/auth/login'))
      }
    } else {
      yield put({
        type: 'user/SET_STATE',
        payload: {
          loading: true,
          dataLoading: true,
          initialStateLoading: true,
        },
      })
      const authCheck = yield call(
        axiosRequest,
        'api/v1/users/me',
        'get',
        null,
        true,
      )
      if (authCheck.data && authCheck.data.success) {
        yield put({
          type: 'user/SET_STATE',
          payload: {
            loading: false,
            isLoggedIn: true,
            user: authCheck.data.user,
            initialStateLoading: false,
            dataLoading: false,
          },
        })
        authorized = true
        if (/^\/auth(?=\/|$)/i.test(history.location.pathname)) {
          yield put(push('/app'))
        }
      }
    }
  } catch (e) {
    axiosErrorUtil(e)
    yield put({
      type: actions.SET_STATE,
      payload: {
        user: {},
        loading: false,
        dataLoading: false,
        isLoggedIn: false,
        initialStateLoading: false,
      },
    })
    if (
      !authorized &&
      !/^\/auth(?=\/|$)/i.test(history.location.pathname) &&
      history.location.pathname !== '/'
    ) {
      yield put(push('/auth/login'))
    }
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOGIN, LOGIN),
    takeEvery(actions.LOGOUT, LOGOUT),
    AUTH_CHECK(),
  ])
}
