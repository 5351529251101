import {
  RiBarChartBoxAiLine,
  RiFileCopy2Line,
  RiSettingsLine,
  RiDashboardLine,
} from 'react-icons/ri'
import { TbDeviceIpadHorizontalSearch } from 'react-icons/tb'

const UserMenu = [
  {
    key: 'userHome',
    path: '/app',
    name: 'Dashboard',
    icon: <RiDashboardLine />,
    mobile: true,
  },
  {
    key: 'userAnalytics',
    path: '/app/analytics',
    name: 'Analytics',
    icon: <RiBarChartBoxAiLine />,
    mobile: true,
  },
  {
    key: 'userTrades',
    path: '/app/trades',
    name: 'Trades',
    icon: <RiFileCopy2Line />,
    mobile: false,
  },
  {
    key: 'userBacktesting',
    path: '/app/backtesting',
    name: 'Backtests',
    icon: <TbDeviceIpadHorizontalSearch />,
    mobile: true,
  },
  // {
  //   key: 'userAccounts',
  //   path: '/app/user-accounts',
  //   name: 'My Accounts',
  //   icon: <RiBriefcase4Fill />,
  // },
  {
    key: 'settings',
    path: '/app/settings',
    name: 'Settings',
    icon: <RiSettingsLine />,
    mobile: false,
  },
]

export default UserMenu
