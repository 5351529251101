import { combineReducers } from '@reduxjs/toolkit'

import users from './users/reducers'
import metadata from './metadata/reducers'

const createRootReducer = (routerReducer) =>
  combineReducers({
    router: routerReducer,
    users,
    metadata,
  })

export default createRootReducer
