import React from 'react'

const LandingLayout = ({ children }) => {
  return (
    <div>
      <div>{children}</div>
    </div>
  )
}

export default LandingLayout
