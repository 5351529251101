import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Logo from '../../ui/logo'
import AdminMenu from '../../../data/menus/admin-menu'
import UserMenu from '../../../data/menus/user-menu'

import styles from './sidebar.module.scss'

const AppSidebar = ({ user, setSidebarOpen }) => {
  const location = useLocation()

  const getMenu = () => {
    if (user.is_admin) {
      return AdminMenu
    }

    return UserMenu
  }

  return (
    <div className={styles.sidebar}>
      <div className={styles.logo}>
        <Link to="/app">
          <Logo />
        </Link>
      </div>
      <div className={styles.menu}>
        <ul>
          {getMenu().map((item) => {
            return (
              <li
                key={`sidebarMenu_${item.key}`}
                data-active={
                  location.pathname === '/app'
                    ? item.path === '/app'
                    : location.pathname.includes(item.path) &&
                      item.path !== '/app'
                }
              >
                <Link to={item.path} onClick={() => setSidebarOpen(false)}>
                  <div className={styles.icon}>{item.icon}</div>
                  <div className={styles.name}>{item.name}</div>
                </Link>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}

export default AppSidebar
